<template>
  <div class="location-create">
    <h1 class="mb-4">
      {{ $t('app.page.locationCreate.title') }}
    </h1>
    <div class="mb-6">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="4"
            class="pr-md-4">
            <div class="mb-2">
              {{ $t('app.label.locationCode') }}
            </div>
            <v-text-field
              v-model="form.code"
              :rules="codeRules"
              :placeholder="$t('app.placeholder.locationCode')"
              dense
              outlined
              required />
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="pr-md-4">
            <div class="mb-2">
              {{ $t('app.label.locationName') }}
            </div>
            <v-text-field
              v-model="form.name"
              :rules="nameRules"
              :placeholder="$t('app.placeholder.locationName')"
              dense
              outlined
              required />
          </v-col>
          <v-col
            cols="12"
            md="4">
            <div class="mb-2">
              {{ $t('app.label.telephone') }}
            </div>
            <v-text-field
              v-model="form.phoneNo"
              :rules="phoneNoRules"
              :onkeypress="onkeypress"
              :placeholder="$t('app.placeholder.telephone')"
              type="tel"
              maxlength="10"
              dense
              outlined
              required />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="4"
            class="pr-md-4">
            <div class="mb-2">
              {{ $t('app.label.type') }}
            </div>
            <v-select
              v-model="form.type"
              :rules="typeRules"
              :placeholder="$t('app.placeholder.type')"
              :items="roles"
              item-text="text"
              item-value="value"
              dense
              outlined
              required />
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="pr-md-4">
            <div class="mb-2">
              {{ $t('app.label.latitude') }}
            </div>
            <v-text-field
              v-model="form.latitude"
              :rules="latitudeRules"
              :placeholder="$t('app.placeholder.latitude')"
              dense
              outlined
              required />
          </v-col>
          <v-col
            cols="12"
            md="4">
            <div class="mb-2">
              {{ $t('app.label.longitude') }}
            </div>
            <v-text-field
              v-model="form.longitude"
              :rules="longitudeRules"
              :placeholder="$t('app.placeholder.longitude')"
              dense
              outlined
              required />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="4"
            class="pr-md-4">
            <div class="mb-2">
              {{ $t('app.label.description') }}
            </div>
            <v-text-field
              v-model="form.description"
              :rules="descriptionRules"
              :placeholder="$t('app.placeholder.description')"
              dense
              outlined
              required />
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="pr-md-4">
            <div class="mb-2">
              {{ $t('app.label.address') }}
            </div>
            <v-text-field
              v-model="form.address"
              :placeholder="$t('app.placeholder.address')"
              dense
              outlined />
          </v-col>
          <v-col
            cols="12"
            md="4">
            <div class="mb-2">
              {{ $t('app.label.province') }}
            </div>
            <v-combobox
              v-model="form.province"
              :rules="provinceRules"
              :placeholder="$t('app.placeholder.province')"
              :items="provinces"
              dense
              outlined
              required />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="4"
            class="pr-md-4">
            <div class="mb-2">
              {{ $t('app.label.district') }}
            </div>
            <v-text-field
              v-model="form.district"
              :rules="districtRules"
              :placeholder="$t('app.placeholder.district')"
              dense
              outlined
              required />
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="pr-md-4">
            <div class="mb-2">
              {{ $t('app.label.subDistrict') }}
            </div>
            <v-text-field
              v-model="form.subDistrict"
              :rules="subDistrictRules"
              :placeholder="$t('app.placeholder.subDistrict')"
              dense
              outlined
              required />
          </v-col>
          <v-col
            cols="12"
            md="4">
            <div class="mb-2">
              {{ $t('app.label.postcode') }}
            </div>
            <v-text-field
              v-model="form.postcode"
              :rules="postcodeRules"
              :onkeypress="onkeypress"
              :placeholder="$t('app.placeholder.postcode')"
              maxlength="5"
              dense
              outlined
              required />
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div class="text-right">
      <v-btn
        class="mr-4"
        text
        depressed
        @click="cancel()">
        {{ $t('app.btn.cancel') }}
      </v-btn>
      <v-btn
        :loading="isSubmitting"
        color="primary"
        depressed
        @click="submit()">
        {{ $t('app.btn.submit') }}
      </v-btn>
    </div>
    <v-divider class="my-6" />
    <v-btn
      height="40"
      color="secondary"
      depressed
      @click="openImport()">
      {{ $t('app.btn.importCsv') }}
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { locationForm } from '@/mixins/location'
import { data } from '@/assets/utils/json/province.json'
import LocationProvider from '@/resources/axios/providers/location'

export default {
  name: 'LocationCreate',
  mixins: [locationForm],
  data () {
    return {
      provinces: data
    }
  },
  methods: {
    ...mapActions({
      showErrorMessage: 'notification/showErrorMessage',
      closeErrorMessage: 'notification/closeErrorMessage'
    }),
    cancel () {
      this.$router.push({ name: 'Location' })
    },
    openImport () {
      this.$router.push({ name: 'LocationImport' })
    },
    async submit () {
      if (this.isSubmitting) { return }
      const valid = this.$refs.form.validate()
      if (valid) {
        this.isSubmitting = true
        const res = await LocationProvider.createLocation({
          code: this.form.code,
          name: this.form.name,
          description: this.form.description,
          phone_no: this.form.phoneNo,
          type: this.form.type,
          latitude: Number(this.form.latitude),
          longitude: Number(this.form.longitude),
          address: this.form.address,
          province: this.form.province,
          district: this.form.district,
          sub_district: this.form.subDistrict,
          postcode: this.form.postcode,
          status: this.form.status
        })
        if (res) {
          this.showErrorMessage({
            message: this.$t('app.alert.create'),
            type: 'success'
          })
          setTimeout(() => {
            this.closeErrorMessage()
          }, 2000)
          this.isSubmitting = false
          this.cancel()
        }
      }
    }
  }
}
</script>

<style scoped>
  .location-create {

  }
  .location-create__container {

  }
</style>
